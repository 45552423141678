<template>
  <main class="rooms">
    <h1 class="heading">My Orders</h1>
    <div class="rooms__header">
      <div
        class="filter__item filter__item_select"
        style="width: 100%; padding: 0; display: flex;margin-bottom: 15px;"
      >

        <v-btn v-if="isMobile" tile small @click="sheet = true" outlined icon><v-icon>mdi-tune</v-icon></v-btn>

        <v-bottom-sheet v-model="sheet" scrollable>
          <v-list
              style="border-top-right-radius: 5px; border-top-left-radius: 5px"
          >
            <div style="font-weight:bold;font-size:20px;margin-bottom:15px;display: flex;align-items: center;justify-content: center;position: relative">
              <div>Filter</div>
                <v-btn small style="position: absolute;right: 15px;" @click="sheet = false" text color="primary">Done</v-btn>
            </div>

            <v-list-item>
              <v-text-field
                  outlined
                  dense
                  style="padding: 0 15px"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
              ></v-text-field>
            </v-list-item>
            <v-list-item>
              <v-autocomplete
                  flat
                  hide-details
                  multiple
                  chips
                  placeholder="Order status"
                  outlined
                  style="padding: 0 15px"
                  dense
                  clearable
                  :items="columnValueList('order_status')"
                  v-model="filters['order_status']"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 5">
                    {{ item === "is_processing" ? "In proccessing" : item }}
                  </v-chip>
                  <span v-if="index === 5" class="grey--text caption">
              (+{{ filters["order_status"].length - 5 }} others)
            </span>
                </template>
              </v-autocomplete>
            </v-list-item>
            <v-list-item>
              <v-autocomplete
                  flat
                  hide-details
                  multiple
                  chips
                  outlined
                  placeholder="Pay status"
                  style="padding: 0 15px"
                  dense
                  clearable
                  :items="columnValueList('pay_status')"
                  v-model="filters['pay_status']"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 5">
                    {{ item }}
                  </v-chip>
                  <span v-if="index === 5" class="grey--text caption">
              (+{{ filters["pay_status"].length - 5 }} others)
            </span>
                </template>
              </v-autocomplete>
            </v-list-item>
          </v-list>
        </v-bottom-sheet>
        <v-autocomplete
          style="padding: 0 15px"
          placeholder="Объект"
          outlined
          hide-details
          dense
          :items="$store.state.objects.list"
          item-text="name"
          v-model="$store.state.objects.item"
          return-object
          @change="changeObj()"
        ></v-autocomplete>

        <v-text-field
          outlined
          v-if="!isMobile"
          dense
          style="padding: 0 15px"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>

        <DatePicker
          :shortcuts="shortcuts"
          format="DD.MM.YYYY"
          v-model="dates"
          @change="changeDates"
          type="date"
          style="height: 40px;"
          :height="40"
          range
          placeholder="Select datetime range"
        >
          <template v-slot:input>
            <v-text-field
              solo
              flat
              style="height: 40px;"
              outlined
              :placeholder="$t('statistics[\'Период\']')"
              readonly
              hide-details
              dense
              v-model="date_model"
            ></v-text-field>
          </template>
        </DatePicker>
        <v-autocomplete
          flat
          hide-details
          v-if="!isMobile"
          multiple
          chips
          placeholder="Order status"
          outlined
          style="padding: 0 15px"
          dense
          clearable
          :items="columnValueList('order_status')"
          v-model="filters['order_status']"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 5">
              {{ item === "is_processing" ? "In proccessing" : item }}
            </v-chip>
            <span v-if="index === 5" class="grey--text caption">
              (+{{ filters["order_status"].length - 5 }} others)
            </span>
          </template>
        </v-autocomplete>

        <v-autocomplete
          flat
          hide-details
          multiple
          chips
          outlined
          v-if="!isMobile"
          placeholder="Pay status"
          style="padding: 0 15px"
          dense
          clearable
          :items="columnValueList('pay_status')"
          v-model="filters['pay_status']"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 5">
              {{ item }}
            </v-chip>
            <span v-if="index === 5" class="grey--text caption">
              (+{{ filters["pay_status"].length - 5 }} others)
            </span>
          </template>
        </v-autocomplete>
      </div>
      <v-data-table
        class="table rooms__table"
        :headers="headers"
        :search="search"
        hide-default-footer
        :disable-sort="isMobile"
        no-data-text="You don't have any orders"
        :items="filteredItems"
        :items-per-page="999"
      >
        <template v-slot:body="{ items }">
          <tbody v-if="items.length">
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell">
                <v-layout justify-center>
                  {{ formatDate(item.created_at) }}
                </v-layout>
              </td>
              <td class="d-block d-sm-table-cell">
                <v-layout justify-center>
                  {{ item.id }}
                </v-layout>
              </td>

              <td class="d-block d-sm-table-cell">
                <v-layout justify-center>
                  Total: {{ totalOrder(item.order_data) }}
                </v-layout>
              </td>
              <td class="d-block d-sm-table-cell">
                <v-layout justify-center align-center column>
                  <span v-if="item.first_name">
                    First Name: item.first_name
                  </span>
                  <span v-if="item.last_name">
                    Last name: {{ item.last_name }}
                  </span>
                  <span v-if="item.email"> Email: {{ item.email }} </span>
                  <span v-if="item.phone"> Phone: {{ item.phone }} </span>
                  <span v-if="item.room_number">
                    Room: {{ item.room_number }}
                  </span>
                  <v-btn
                    small
                    depressed
                    outlined
                    style="
                      background: #fff;
                      margin-bottom: 10px;
                      margin-top: 5px;
                      max-width: 50%;
                    "
                    color="primary"
                    @click="show(item)"
                  >
                    Detail
                  </v-btn>
                </v-layout>
              </td>
              <td class="d-block d-sm-table-cell">
                <v-layout justify-center>
                  <v-autocomplete
                    style="margin: 0; width: 200px; max-width: 200px"
                    dense
                    hide-details
                    outlined
                    placeholder="Order status"
                    :items="order_status_types"
                    v-model="item.order_status"
                    @change="saveChanges(item)"
                  ></v-autocomplete>
                </v-layout>
              </td>
              <td class="d-block d-sm-table-cell">
                <v-layout justify-center>
                  <v-autocomplete
                    style="margin: 0; width: 150px; max-width: 150px"
                    dense
                    hide-details
                    outlined
                    placeholder="Pay status"
                    :items="order_pay_types"
                    v-model="item.pay_status"
                    @change="saveChanges(item)"
                  ></v-autocomplete>
                </v-layout>
              </td>

              <td class="d-block d-sm-table-cell">
                <v-layout justify-center>
                  <v-icon @click="deleteOrderShow(item.id)">mdi-delete</v-icon>
                </v-layout>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td
                class="d-block d-sm-table-cell"
                colspan="7"
                style="text-align: center; padding: 0 15px; font-weight: normal"
              >
                You don't have any orders
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <v-dialog :fullscreen="$store.state.isMobile" max-width="700px" v-model="dialog" v-if="selectedItem">
      <div class="popup__content" style="min-height: 600px">
        <v-btn class="popup__close" small icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="popup__title" style="margin-bottom: 15px">
          Order Detail # {{ selectedItem.id }}
        </div>
        <v-tabs>
          <v-tab>Info</v-tab>
          <v-tab>Customer</v-tab>
          <v-tab>History</v-tab>
          <v-tab-item style="padding-top: 15px">
            <div style="margin-bottom: 30px" v-if="selectedItem.hidden_id">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 15px;
                "
              >
                <div>Hidden id</div>
                <div>
                  {{ selectedItem.hidden_id }}
                </div>
              </div>
            </div>

            <div style="margin-bottom: 30px" v-if="selectedItem.hidden_title">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 15px;
                "
              >
                <div>Hidden title</div>
                <div >
                  {{ selectedItem.hidden_title }}
                </div>
              </div>
            </div>

            <div>
              <ul
                style="
                  font-weight: normal;
                  font-size: 15px;
                  margin-bottom: 30px;
                  padding-left: 0;
                "
              >
                <li
                  v-for="(data, index) in selectedItem.order_data"
                  :key="index + 'index' + index"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                  "
                >
                  <div
                    v-for="(item, itemIndex) in data"
                    :key="item + 'index' + itemIndex"
                    style="
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      border-bottom: 1px solid rgb(238, 238, 238);
                      padding-bottom: 5px;
                    "
                  >
                    <div
                      v-if="item.radio === 'checkbox' && item.text"
                      style="
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div>{{ item.label }}</div>
                      <div>{{ item.price }} {{ currency }}</div>
                    </div>
                    <div
                      v-else-if="item.radio === 'text'"
                      style="
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div>{{ item.label }}</div>
                      <div>{{ item.price }} {{ currency }}</div>
                    </div>
                    <div
                      v-else-if="item.radio === 'number'"
                      style="
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div>{{ item.label }}</div>
                      <div>
                        {{ item.count }}*{{ item.price }} {{ currency }} =
                        {{ item.count * +item.price }} {{ currency }}
                      </div>
                    </div>
                    <div
                      v-else-if="item.radio === 'date'"
                      style="
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div>{{ item.label }}</div>
                      <div>{{ formatDateDetail(item.text) }}</div>
                    </div>
                    <div
                      v-else-if="item.radio === 'time'"
                      style="
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div>{{ item.label }}</div>
                      <div>{{ item.text }}</div>
                    </div>
                    <div
                      v-else-if="item.radio === 'radio'"
                      style="
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div>{{ item.label }}</div>
                      <div>{{ item.price }} {{ currency }}</div>
                    </div>
                    <div
                      v-else-if="item.radio === 'notice'"
                      style="width: 100%"
                    >
                      <div>{{ item.label }}</div>
                      <div>{{ item.text }}</div>
                    </div>
                    <div>
                      <div
                        style="color: rgb(112, 112, 122); font-size: 12px"
                        v-if="item.hidden_id"
                      >
                        id: ({{ item.hidden_id }})
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                  "
                >
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: space-between;
                      margin-top: 15px;
                      font-weight: bold;
                    "
                  >
                    <div>Total</div>
                    <div>{{ totalOrder(selectedItem.order_data) }}</div>
                  </div>
                </li>
              </ul>

              <div style="margin-bottom: 30px">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 15px;
                  "
                >
                  <div>Change order status</div>
                  <v-autocomplete
                    style="margin: 0; max-width: 200px"
                    dense
                    hide-details
                    outlined
                    placeholder="Order status"
                    :items="order_status_types"
                    v-model="selectedItem.order_status"
                    @change="saveChanges(selectedItem)"
                  ></v-autocomplete>
                </div>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div>Order order pay status</div>
                  <v-autocomplete
                    style="margin: 0; max-width: 200px"
                    dense
                    hide-details
                    outlined
                    placeholder="Pay status"
                    :items="order_pay_types"
                    v-model="selectedItem.pay_status"
                    @change="saveChanges(selectedItem)"
                  ></v-autocomplete>
                </div>
              </div>
            </div>
            <div>
              <div
                style="text-align: center; font-size: 20px; margin-bottom: 15px"
              >
                Notice
              </div>
              <v-textarea
                outlined
                solo
                dense
                style="width: 100%"
                v-model="selectedItem.comment"
              >
              </v-textarea>
              <v-btn
                outlined
                dense
                small
                color="primary"
                @click="saveChanges(selectedItem)"
                >Save notice</v-btn
              >
            </div>
          </v-tab-item>
          <v-tab-item style="padding-top: 15px">
            <div>
              <ul
                style="
                  font-weight: normal;
                  font-size: 15px;
                  margin-bottom: 30px;
                  padding-left: 0;
                "
              >
                <li
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                  "
                >
                  <div
                    style="display: flex; flex-direction: column; width: 100%"
                  >
                    <div
                      v-if="selectedItem.first_name"
                      style="
                        padding-bottom: 5px;
                        border-bottom: 1px solid rgb(238, 238, 238);
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div>First name</div>
                      <div>{{ selectedItem.first_name }}</div>
                    </div>
                    <div
                      v-if="selectedItem.last_name"
                      style="
                        padding-bottom: 5px;
                        border-bottom: 1px solid rgb(238, 238, 238);
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div>Last name</div>
                      <div>{{ selectedItem.last_name }}</div>
                    </div>
                    <div
                      v-if="selectedItem.room_number"
                      style="
                        padding-bottom: 5px;
                        border-bottom: 1px solid rgb(238, 238, 238);
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div>Room number</div>
                      <div>{{ selectedItem.room_number }}</div>
                    </div>
                    <div
                      v-if="selectedItem.phone_number"
                      style="
                        padding-bottom: 5px;
                        border-bottom: 1px solid rgb(238, 238, 238);
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div>Phone number</div>
                      <div>{{ selectedItem.phone_number }}</div>
                    </div>
                    <div
                      v-if="selectedItem.email"
                      style="
                        padding-bottom: 5px;
                        border-bottom: 1px solid rgb(238, 238, 238);
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div>Email</div>
                      <div>{{ selectedItem.email }}</div>
                    </div>
                    <div
                      v-if="selectedItem.pay_type"
                      style="
                        padding-bottom: 5px;
                        border-bottom: 1px solid rgb(238, 238, 238);
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div>Pay type</div>
                      <div>{{ selectedItem.pay_type }}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </v-tab-item>
          <v-tab-item style="padding-top: 15px">
            <v-data-table
              :headers="headersHistory"
              hide-default-footer
              :items="selectedItem.order_history"
            >
              <template v-slot:body="{ items }">
                <tbody v-if="items.length">
                  <tr v-for="item in items" :key="item.id">
                    <td
                      class="d-block d-sm-table-cell"
                      style="text-align: center"
                    >
                      <img
                        style="height: 30px; width: 30px; border-radius: 15px"
                        :src="
                          item.user && item.user.avatar
                            ? `${VUE_APP_STORAGE_URL + item.user.avatar}`
                            : require('@/assets/icons/user_placeholder.svg')
                        "
                        alt=""
                      />
                    </td>
                    <td
                      class="d-block d-sm-table-cell"
                      style="text-align: center"
                    >
                      {{ item.user ? item.user.name : "" }}
                    </td>
                    <td
                      class="d-block d-sm-table-cell"
                      style="text-align: center"
                    >
                      {{ formatDateDetail(item.created_at) }}
                    </td>
                    <td
                      class="d-block d-sm-table-cell"
                      style="text-align: center"
                    >
                      {{ formatTimeDetail(item.created_at) }}
                    </td>
                    <td
                      class="d-block d-sm-table-cell"
                      style="text-align: center"
                    >
                      {{ changeStatus(item.old_status) }}
                      {{ item.old_status ? "&#10140;" : "" }}
                      {{ changeStatus(item.new_status) }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="d-block d-sm-table-cell"
                      colspan="5"
                      style="text-align: center"
                    >
                      This order do not have some updates
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table></v-tab-item
          >
        </v-tabs>
      </div>
    </v-dialog>
    <PopupDelete
      ref="PopupDelete"
      :title="`Are you sure about delete order № ${deleteId}`"
      @confirm="deleteOrderConfirm"
    />
  </main>
</template>

<script>
import store from "@/store";
import titleMixin from "../mixins/titleMixin";
import moment from "moment";
import PopupDelete from "../components/Popup/PopupDelete";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import HotelService from "@/services/hotel.service";
export default {
  name: "Order",
  components: { PopupDelete, DatePicker },
  mixins: [titleMixin],
  title() {
    return "Orders";
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.objects.list.length) {
      store
        .dispatch("objects/getList")
        .then((data) => {
          next();
        })
        .catch(() => {
          next();
        });
    } else {
      next();
    }
  },
  data() {
    return {
      sheet:false,
      isMobile:false,
      currency: "",
      shortcuts: [
        {
          text: "Today",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 1 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Yesterday",
          onClick() {
            const start = new Date();
            const end = new Date();
            end.setTime(end.getTime() + 1 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Last 7 day",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Last 30 day",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 30 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "This month",
          onClick() {
            const start = new Date();
            const end = new Date();
            const firstDayCurrentMonth = new Date(
              start.getFullYear(),
              start.getMonth(),
              1
            );
            const lastDayCurrefntMonth = new Date(
              end.getFullYear(),
              end.getMonth() + 1,
              0
            );
            const date = [firstDayCurrentMonth, lastDayCurrefntMonth];
            return date;
          },
        },
        {
          text: "Last month",
          onClick() {
            const dateLast = new Date();
            const firstDayCurrentMonth = new Date(
              dateLast.getFullYear(),
              dateLast.getMonth() - 1,
              1
            );
            const lastDayCurrefntMonth = new Date(
              dateLast.getFullYear(),
              dateLast.getMonth(),
              0
            );
            const date = [firstDayCurrentMonth, lastDayCurrefntMonth];
            return date;
          },
        },
      ],
      deleteId: 0,
      dialog: false,
      selectedItem: null,
      date_model: null,
      dates: [],
      menu_dates: false,
      date_end: null,
      date_start: null,
      search: "",
      filters: {
        order_status: [],
        pay_status: [],
      },
      headers: [
        { text: "Time", value: "created_at", align: "center", filtered: true },
        { text: "Order ID", value: "id", align: "center", filtered: true },
        { text: "Total Price", align: "center", filtered: true },
        {
          text: "Order info",
          value: "created_at",
          align: "center",
          filtered: true,
        },
        { text: "Order status", value: "order_status", align: "center" },
        { text: "Pay status", value: "pay_status", align: "center" },
        { text: "Options", align: "center" },
      ],
      headersHistory: [
        { text: "Avatar", align: "center", sortable: true },
        { text: "Name", align: "center", sortable: true },
        { text: "Date", align: "center", sortable: true },
        { text: "Time", align: "center", sortable: true },
        { text: "Status", align: "center", sortable: true },
      ],
      order_status_types: [
        {
          text: "New",
          value: "new",
        },
        {
          text: "In processing",
          value: "is_processing",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Deleted",
          value: "deleted",
        },
        {
          text: "Canceled",
          value: "canceled",
        },
      ],
      order_pay_types: [
        {
          text: "Not paid",
          value: "not paid",
        },
        {
          text: "Paid",
          value: "paid",
        },
      ],
      items: [],
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter(
        (x) =>
          this.filterOrderStatus(x.order_status) &&
          this.filterPayStatus(x.pay_status) &&
          +this.$moment(this.dates[0]).format("x") <=
            +this.$moment(x.created_at).format("x") &&
          +this.$moment(this.dates[1]).format("x") >=
            +this.$moment(x.created_at)
      );
    },
    VUE_APP_STORAGE_URL() {
      return process.env.VUE_APP_STORAGE_URL;
    },
  },
created() {
  this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
},
  methods: {
    changeStatus(status) {
      const candidate = this.order_status_types.find((x) => status === x.value);
      return candidate ? candidate.text : "";
    },
    totalOrder(arr) {
      let total = 0;
      let currency = "";
      for (let i = 0; i < arr.length; i++) {
        for (let index = 0; index < arr[i].length; index++) {
          let item = arr[i][index];
          if (item.radio === "text") {
            total += +item.price;
            currency = this.currency;
          } else if (item.radio === "number") {
            total += +item.price * +item.count;
            currency = this.currency;
          } else if (item.radio === "checkbox") {
            total += +item.price;
            currency = this.currency;
          } else if (item.radio === "radio") {
            total += +item.price;
            currency = this.currency;
          }
        }
      }
      const totalPrice = total.toFixed(2);
      if(totalPrice[totalPrice.length-1] === '0' && totalPrice[totalPrice.length-2] === '0'){
        return totalPrice.slice(0,totalPrice.indexOf('.'))+" "+currency;
      }else{
        return totalPrice+" "+currency
      }
    },
    formatTimeDetail(date) {
      return moment(date).format("HH:mm");
    },
    filterOrderStatus(status) {
      if (this.filters.order_status.length) {
        return this.filters.order_status.includes(status);
      } else {
        return true;
      }
    },
    filterPayStatus(status) {
      if (this.filters.pay_status.length) {
        return this.filters.pay_status.includes(status);
      } else {
        return true;
      }
    },
    changeDates() {
      this.menu_dates = false;
      if (this.dates[0] && this.dates[1]) {
        if (this.dates[0] > this.dates[1]) {
          let start = this.dates[0];
          let end = this.dates[1];
          this.dates[0] = end;
          this.dates[1] = start;
        }
        this.$emit("changeDates", {
          date_start: this.$moment(this.dates[0]).unix(),
          date_end: this.$moment(this.dates[1]).unix(),
        });
      } else {
        this.$emit("changeDates", {
          date_start: null,
          date_end: null,
        });
      }
      this.dateRangeText();
    },
    columnValueList(val) {
      return this.items.map((d) => d[val]);
    },
    close() {
      this.dialog = false;
      this.selectedItem = null;
    },
    show(item) {
      this.selectedItem = item;
      this.dialog = true;
    },
    formatDateDetail(date) {
      return moment(date).format("DD.MM.yyyy");
    },
    formatDate(date) {
      return moment(date).format("DD.MM.yyyy HH:mm");
    },
    async saveChanges(item) {
      await this.$axios.put(
        `${process.env.VUE_APP_API_URL}order/${item.id}`,
        item
      );
    },
    async changeObj() {
      this.items = await this.getData(this.$store.state.objects.item.id);
    },
    async getData(id) {
      const response = await this.$axios.get(
        `${process.env.VUE_APP_API_URL}order?hotel_id=${id}`
      );
      // console.log(response);
      return response.data;
    },
    dateRangeText() {
      let date_start = null;
      let date_end = null;
      if (this.dates[0] && this.dates[1]) {
        date_start = this.$moment(this.dates[0]).format("DD.MM.YYYY");
        date_end = this.$moment(this.dates[1]).format("DD.MM.YYYY");
      } else if (this.dates[0]) {
        date_start = this.$moment(this.dates[0]).format("DD.MM.YYYY");
      } else if (this.dates[1]) {
        date_end = this.$moment(this.dates[1]).format("DD.MM.YYYY");
      } else {
        this.date_model = "";
      }

      this.date_model = [date_start, date_end].join(" - ");
    },
    deleteOrderShow(id) {
      this.deleteId = id;
      this.$refs.PopupDelete.open();
    },
    async deleteOrderConfirm() {
      await this.$axios.delete(
        `${process.env.VUE_APP_API_URL}order/${this.deleteId}`
      );
      const candidate = this.items.find((x) => x.id === this.deleteId);
      if (candidate) {
        this.items.splice(this.items.indexOf(candidate), 1);
      }
      this.deleteId = null;
    },
  },
  async mounted() {
    if (this.$route.query.id) {
      const candidate = this.$store.state.objects.list.find(
        (x) => x.id === +this.$route.query.id
      );
      if (candidate) {
        this.$store.state.objects.item = candidate;
      } else {
        this.$store.state.objects.item = this.$store.state.objects.list[0];
      }
    } else {
      this.$store.state.objects.item = this.$store.state.objects.list[0];
    }
    this.dates[0] = this.$moment()
      .subtract(1, "months")
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    this.dates[1] = this.$moment().add(1, "day").format("YYYY-MM-DD");
    this.dateRangeText();
    if (this.$store.state.objects.item) {
      this.items = await this.getData(this.$store.state.objects.item.id);
      this.currency = await HotelService.getHotelCurrency(
        this.$store.state.objects.item.id
      );
    }
  },
};
</script>

<style lang="scss">
.mx-input{
  height:40px;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: inherit !important;
  padding: 6px 0 !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td{
  height:auto;
}
</style>
